<template>
<div>
    <Navbar />
    <div class="container">
        <h1 class="admin-header mt-4 pt-3 pb-4 mb-3">
            Панель администратора
        </h1>
        <div class="col-md-12 autoOrderItem mb-4">
            <div class="autoOrder-inputs mb-3">
              <AdminMenuHorizont/>
              <!-- <OperatorStatusNav/> -->
            </div>
        </div>
     <router-view></router-view>
    </div>
</div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import AdminMenuHorizont from '@/components/AdminMenuHorizont.vue'

//import OperatorStatusNav from '@/components/OperatorStatusNav.vue'
export default {
    data() {
        return {
            opened: [],
            rows: [{
                    id: 1,
                    name: 'Bill',
                    handle: 'bill'
                },
                {
                    id: 2,
                    name: 'Bob',
                    handle: 'bob'
                },
                {
                    id: 3,
                    name: 'Jim',
                    handle: 'jim'
                },
                {
                    id: 4,
                    name: 'Leroy',
                    handle: 'leroy'
                }
            ]
        };
    },
    components: {
        Navbar,
 AdminMenuHorizont,
// OperatorStatusNav
    },
    mounted() {
    this.$router.push('/admin/preparation/assembly')
},
    methods: {
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.admin-header {
    font-size: 40px;
    line-height: 100.74%;
}

.cabinet {
    margin-top: 35px;
    padding-bottom: 80px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    &__nav {
        width: 250px;
    }

    &__content {
        width: calc(100% - 326px);
        //background: #FFFFFF !important;

    }

}

.inputs-line {

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        position: fixed;
        width: 0;
    }

    label {
        display: inline-block;
        background-color: #fff;
        padding: 16px 35px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 109.5%;
        /* or 15px */

        color: #626262;

        border: 1px solid #3F2A2C;
        ;
        margin-bottom: 10px;
        border-radius: 12px;
        margin-right: 10px;
        text-align: center;
        // width: 160px;
        cursor: pointer;
        background: #fff;
    }

    // label:hover {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    // input[type="checkbox"]:focus+label {
    //     background-color: $orange;
    //     border-color: $orange;
    //     color: #fff;
    // }

    input[type="checkbox"]:checked+label,
    input[type="radio"]:checked+label {
        background-color: #3F2A2C;
        border-color: #3F2A2C;
        color: #fff;
    }

    &--orange {
        label {
            background: #F5F5F5;
            border: none;
            padding-left: 48px;
            padding-right: 48px;
            font-size: 18px;
        }

        input[type="checkbox"]:checked+label,
        input[type="radio"]:checked+label {
            background-color: $orange;
            border-color: $orange;
            color: #fff;
            border: none;
        }
    }
}

.admin-order-wrapper {
    background: #F5F5F5;
    border-radius: 9px;
    overflow: hidden;
}

.adminOrder {
    background: #F5F5F5;
    padding-top: 25px;
    padding-bottom: 3px;

    &__header {
        display: flex;
        justify-content: space-between;
        //align-items: center;
        font-family: Montserrat;
        cursor: pointer;

        // margin-bottom: 18px;
        max-width: calc(100% - 34px);
        margin-left: auto;
        margin-right: auto;
    }

    &__header-number {
        font-size: 16px;
        margin-bottom: 17px;

        p {
            padding: 0;
            margin: 0;
        }
    }

    &__header-time {
        font-size: 18px;
        color: #000;
        font-weight: 400;
    }

    .orange {
        color: $orange;
    }

    &__btn {
        margin-top: 20px;
        padding-bottom: 29px;
    }
}

.adminOrderItem {
    display: flex;
    font-family: Montserrat;
    background: #fff;
    opacity: .5;
    padding: 15px;
    height: 102px;
    border-radius: 9px;
    min-width: 234px;
    align-items: center;
    margin-bottom: 10px;

    &__number {
        width: 88px;
        height: 72px;
        background: #000;
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        &--orange {
            background: $orange;
        }

        &--blue {
            background: #0041E8;
        }
    }

    &__desc {
        padding-left: 13px;

        b {
            display: block;

        }

        span {
            display: block;
        }

        &--center {
            width: 100%;
            text-align: center;
        }
    }
}

.showFullOrder {
    font-weight: 500;
    font-size: 16px;
    font-family: Montserrat;
    cursor: pointer;
}

.g-3,
.gx-3 {
    --bs-gutter-x: .6rem;
}

.toggle {
    border: solid #777777;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
}

.opened {
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 14px;
}

.opened .toggle {
    transform: rotate(225deg);
}

.adminOrder__btn {
    display: flex;
    align-items: center;
    b{
        margin-right: 16px;
        font-family: Montserrat;

font-weight: 600;
font-size: 18px;
    }
}
</style>
